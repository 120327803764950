<template lang="pug">
.main
    .sticky-top
        .loader
            .bar(v-if="$store.getters.loading")
        .submenu
            .container-xxl

                .row.no-gutters.submenu-inner
                    .col-10
                        .headline-submenu 
                            img(src="/hy_w.png").mr-3.logo 
                            .wiki-head.d-inline-block Wiki
                    .col-2
                        .float-right
                            span(v-if="$store.state.user").d-inline-block
                                a(href="#", @click="logout();") 
                                    User(:user="$store.state.user", :size="25", :popover="false")
                                    .small.light Logout
                    
                .row.no-gutters.submenu-inner
                
                    //.col-md-12    
                        .menu                
                            router-link(to="browse").mr-5 News 
                            //router-link(to="curator").mr-5 Curator 
              
    .container-xxl
        .row.no-gutters
            .col-12
                router-view(name="main").main-view
            
</template>

<script>


import Vue from 'vue'
import User from '@/components/User'
export default {
  name: 'SubmenuEcosystem',
  props: {
    title: String,
    
  },

  data: function () {
      return {
          selectAll: false
      }
  },

  mounted: function() {
     
  },

  methods: {

       logout() {
        localStorage.removeItem('id_token');
        localStorage.clear();
        Vue.http.headers.common['Authorization'] = 'JWT none';
        document.location.reload();
      },

  },
  computed: {
    
  },
  components: {
      User
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    body {
        background-color: #fff;
    }
</style>
<style scoped lang="scss">
    @import '@/_vars.scss';
     .wiki-head {
        font-family: "IBM Plex Serif" !important;
        font-weight: 800;
        font-size: 1em;
    }

    .main-view {
        //background-color: $light;
        font-family: "IBM Plex Sans" !important;

      
    }
    .submenu { 
        border: 0;
        background-color: $primary;
        color: $light;
        a, .light {
            color: $light;
            display: inline-block;
        }
        .headline-submenu {
            color: $light;
        }
    }

  .logo {
      width: 28px;
      height: 28px;
      margin-top: -2px;
      &:hover {
        opacity: 1;
      }
    }

</style>
