import { render, staticRenderFns } from "./SubmenuWiki.vue?vue&type=template&id=031d84de&scoped=true&lang=pug"
import script from "./SubmenuWiki.vue?vue&type=script&lang=js"
export * from "./SubmenuWiki.vue?vue&type=script&lang=js"
import style0 from "./SubmenuWiki.vue?vue&type=style&index=0&id=031d84de&prod&lang=css"
import style1 from "./SubmenuWiki.vue?vue&type=style&index=1&id=031d84de&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "031d84de",
  null
  
)

export default component.exports